const tl = gsap.timeline();

gsap.defaults({
  duration: 2.5,
});

tl.from(".overlay h2", {
  scale: 0,
  opacity: 0,
})
  //   .to(".overlay h2", {
  //     opacity: 0,
  //     duration: 2,
  //     delay: 0.6,
  //   })
  .to(".loader", {
    width: "100%",
    delay: 0.1,
    duration: 1.5,
  })
  .to(".loader,.overlay ", {
    opacity: 0,
  })

  .from(".content", {
    y: "-100%",
  })
  .from(
    ".illustration",
    {
      x: "-50%",
      scale: 0,
      opacity: 0,
      onComplete: () =>
        document.querySelector(".is-loading").classList.remove("is-loading"),
    },
    "<="
  );
